import * as g from "./modules/grazle"; 
//import {$mergeExtend,$type,$merge} from "./modules/grazle"; 

// import {$, $first} from "./modules/jquery-to-javascript"; 
// import superboots from "./modules/jquery-to-javascript"; 

//TODO: whats the differnce between:
//import {$, $first} 
//AND  
//import $first
//???


//window.log=function(){log.history=log.history||[];log.history.push(arguments);if(this.console){console.log(Array.prototype.slice.call(arguments))}};


///////////////
// DOC READY
///////////////

// Check if the DOMContentLoaded has already been completed
if (document.readyState !== 'loading') {
  startApp();
} else {
  document.addEventListener('DOMContentLoaded', startApp());
}



// A more comprehnsive version:
// if (document.readyState!='loading'){
//  run();
// } else if (document.addEventListener){// modern browsers
//  document.addEventListener('DOMContentLoaded', run);
// } else {// IE <= 8
//  document.attachEvent('onreadystatechange', function(){
//      if (document.readyState=='complete') {
//          run();
//      }
//      }
// }




window.onresize = function(event) {
    //console.log('window resize')
    setUpMobileNav();
};


///////////////
// START APP
///////////////
function startApp() {
    //console.log('app started');

    mobileMenu();
    plantsGalleries();
    outOfStock();
    setUpMobileNav();

}






function mobileMenu(){

    const menu = g.$id('menu');
    const nav =  g.$first('nav');
    const logo =  g.$first('.logo a');

    g.$addEvent(menu, 'click', function(){ 

        //logo.style.backgroundColor = 'red';

        g.$toggleClass(menu, 'open');

        let menuOpen = g.$hasClass(menu, 'open');
        
        if(menuOpen){
           g.$fadeIn(nav);
           g.$addClass(logo, 'mobile-nav');
        } else {
            g.$fadeOut(nav);
            g.$removeClass(logo, 'mobile-nav');
        }

    })

    window.addEventListener("resize", function() {//Better than window.onresize = function(event){} as it picks up orientation changes on ipad etc
        //if(window.innerWidth>1360 && g.$hasClass(menu, 'open')){
            g.$removeClass(menu, 'open');
            g.$removeClass(logo, 'mobile-nav');
        //}
        nav.style.removeProperty('display');
        nav.style.removeProperty('opacity');
    }, false);

}


function plantsGalleries(){

    const galleryThumbs = g.$('.plant-image-thumb');
    //console.log('galleryThumbs.length',galleryThumbs.length)

    const galleryPlantImages = g.$('.plant-image');
    //console.log('galleryPlantImages',galleryPlantImages.length)

    let gallery = document.body.contains(galleryThumbs[0]);
    //console.log('gallery',gallery)
    
    if(gallery){
    
        galleryThumbs.forEach((galleryThumb, i) => {
          galleryThumb.addEventListener('click', () => {
            
            //console.log("forEach running", i);
            
            for (var j = 0; j < galleryPlantImages.length; j++) {
                galleryPlantImages[j].style.display = "none";
            }
            galleryPlantImages[i].style.display = "block";

          });
        });

    }
}


function outOfStock(){    

    const quantity = g.$id('quantity');
    // const quantityInput = g.$first('.quantity');
    const plantContainer = g.$first('.plant-container');
    
    let plantShop = document.body.contains(quantity);
    if(plantShop){
       let stock = quantity.textContent;
       if(stock==0){
            g.$addClass(plantContainer, 'out-of-stock');
       }
       // console.log('stock',stock)
    }

}



function setUpMobileNav(){

    //var navLi = $('nav').find('ul li');
    const navUl = g.$first('.main-nav ul');
    const navLi = g.$('.main-nav ul li');

    // navLi.forEach((thisNavLi, i) => {
    //     thisNavLi.style.border = '1px solid red';
    // });

    //Nav setup for mobile >> overrides default markup
    if(window.innerWidth<=1360){
        
        // var numberOfNavItems = navLi.length;
        // console.log('navLi.length',navLi.length)

        //console.log('window.innerHeight',window.innerHeight)
        //console.log('ul top margin', window.innerHeight/5)
        let margTop = window.innerHeight/5

        navUl.style.marginTop = margTop+'px';
        console.log('navUl.style.marginTop',navUl.style.marginTop)

        //var pixelHeightOfRequiredFraction = window.innerHeight/(numberOfNavItems/2);
        
        //navLi.css('height',(pixelHeightOfRequiredFraction-((pixelHeightOfRequiredFraction/3)/2))+'px');
        //navLi.find('a').css('padding',(pixelHeightOfRequiredFraction/3)+'px 0');

    } else {
        navUl.style.marginTop = 0;
    }

}





/*==========================================
    External links
============================================*/
 function externalLinks(){

    // $('a[data-target="external"]').on('click',function(e){
    //     //log('clicked external')
    //     var thisExternalLink = $(this).attr('href');
    //     window.open(thisExternalLink);
    //     e.preventDefault();
    // });

}//end externalLinks

        

    

/*==========================================
    touchDeviceDetection
============================================*/  
function touchDeviceDetection(){

    isTouchDevice = 'ontouchstart' in document.documentElement;
    //console.log('this is a touch device: '+isTouchDevice)
    
    if(isTouchDevice){
        //from iscroll code - stops screen moving ontouch - locks that baby down
        document.addEventListener('touchmove', function(e){ e.preventDefault(); }, false);
    
        //console.log('is touch with fastclick')
        //new FastClick(document.body);//add fastclick (plugin)

    }


    //initSetA1 = 'true'; 
}






